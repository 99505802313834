import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/seo';
import Layout from '../components/Layout';

class SiteIndex extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <Layout>
                <SEO title={undefined} theme="orange" />
                <article>
                    <Img
                        className="profile-pic"
                        title="Me"
                        fixed={data.file.childImageSharp.fixed}
                    />
                </article>
                <header>
                    <h1>Rowan</h1>
                    <p>
                        A builder and designer of technology based in New Zealand.
                        <br />
                        Co-founder of{' '}
                        <a href="https://www.prosaic.works/" target="_blank">
                            Prosaic
                        </a>
                        . This website is a scrapbook of whatever.
                    </p>
                </header>
                <article>
                    <ul className="hero-links">
                        <li>
                            <Link to="/dispatch" className="site-nav--forward">
                                Web log
                            </Link>
                        </li>
                        <li>
                            <Link to="/photography" className="site-nav--forward">
                                Photography
                            </Link>
                        </li>
                        <li>
                            <Link to="/books" className="site-nav--forward">
                                Reading list
                            </Link>
                        </li>
                        <li>
                            <Link to="/work" className="site-nav--forward">
                                Work
                            </Link>
                        </li>
                        <li>
                            <Link to="/misc" className="site-nav--forward">
                                Misc
                            </Link>
                        </li>
                    </ul>
                </article>
            </Layout>
        );
    }
}

export default SiteIndex;

export const query = graphql`
    query profilePic {
        file(relativePath: { eq: "profile.jpg" }) {
            childImageSharp {
                fixed(width: 235, height: 235) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
